import { Modal, Spin, DatePicker } from "antd";
import { ModalProps } from "antd/lib/modal";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { RangePickerValue } from "antd/lib/date-picker/interface";
import idx from "idx";
import { ExportOrders, ExportOrdersVariables } from "./types/ExportOrders";
import EXPORT_ORDERS from "./exportOrders.graphql";
import React, { FC, useCallback, useRef, useState } from "react";
import { useErrorHandler } from "utils";

const { RangePicker } = DatePicker;

type Props = Omit<ModalProps, "onCancel" | "onOk"> & { onClose?: () => void };

const ExportOrdersModal: FC<Props> = ({ onClose, ...props }) => {
  const [exportOrders, { loading, error, data }] = useMutation<
    ExportOrders,
    ExportOrdersVariables
  >(EXPORT_ORDERS, { errorPolicy: "all" });

  useErrorHandler(error);

  const modalRef = useRef<Modal>(null);

  const [value, setValue] = useState<RangePickerValue>([
    moment().add(-1, "month"),
    moment(),
  ]);

  const handleSubmit = useCallback<
    NonNullable<ModalProps["onOk"]>
  >(async () => {
    const from = value[0];
    const to = value[1];

    await exportOrders({
      variables: {
        from: from ? from.toISOString() : "",
        to: to ? to.toISOString() : "",
      },
    });
  }, [exportOrders, value]);

  const url = idx(data, x => x.srv.order.export.url) || undefined;

  return (
    <Modal
      ref={modalRef}
      title="Экспорт заказов"
      width={374}
      {...props}
      onCancel={onClose}
      onOk={handleSubmit}
    >
      <Spin spinning={loading}>
        <RangePicker value={value} onChange={setValue} />
        {url && (
          <iframe src={url} style={{ display: "none " }} onLoad={onClose} />
        )}
      </Spin>
    </Modal>
  );
};

export default ExportOrdersModal;
