import { useState, useCallback } from "react";

export function useToggleState(initialState = false) {
  const [value, setValue] = useState(initialState);

  const show = useCallback(() => setValue(true), []);

  const hide = useCallback(() => setValue(false), []);

  const toggle = useCallback(() => setValue(!value), [value]);

  return {
    value,
    show,
    hide,
    toggle,
  };
}
