import { Button } from "antd";
import idx from "idx";
import Table, { ColumnProps } from "antd/lib/table";
import GET_ORDER_LIST from "./getOrderList.graphql";
import {
  GetOrderList,
  GetOrderListVariables,
  GetOrderList_orders_items as Order,
  GetOrderList_orders_items_products as Product,
} from "./types/GetOrderList";
import React, { FC, useCallback, useMemo } from "react";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import GraphQLTable, {
  creationTime,
  lastModificationTime,
  GraphQLTableProps,
} from "components/graphQLTable";
import ExportOrdersModal from "components/export/orders";
import { useToggleState } from "utils/useToggleState";
import { ImportOrdersModal } from "components/import";

type OrderTableProps = GraphQLTableProps<
  Order,
  GetOrderList,
  GetOrderListVariables
>;

const Orders: FC = () => {
  const orderTableColumns = useMemo<Array<ColumnProps<Order>>>(
    () => [
      { dataIndex: "id", title: "#", sorter: true },
      { dataIndex: "code", title: "Номер", sorter: true },
      { dataIndex: "status", title: "Статус", sorter: true },
      {
        dataIndex: "totalPrice",
        title: "Сумма",
        render: text => `${text}₽`,
        sorter: true,
      },
      { dataIndex: "user.buyer.name", title: "Покупатель", sorter: true },
      { dataIndex: "user.buyer.code", title: "Код пок-ля", sorter: true },
      {
        dataIndex: "consignee.name",
        title: "Грузополучатель",
        sorter: true,
      },
      {
        dataIndex: "consignee.code",
        title: "Код груз-ля",
        sorter: true,
      },
      creationTime,
      lastModificationTime,
    ],
    []
  );

  const productTableColumns = useMemo<Array<ColumnProps<Product>>>(
    () => [
      { dataIndex: "id", title: "#" },
      {
        dataIndex: "productPrice.product.name",
        title: "Наименование",
      },
      {
        dataIndex: "productPrice.product.vendorCode",
        title: "Код продукта",
      },
      {
        dataIndex: "productPrice.price",
        title: "Цена",
        render: (
          _,
          {
            productPrice: {
              price,
              product: { unit },
            },
          }
        ) => `${price}₽/${unit}`,
      },
      { dataIndex: "count", title: "Количество" },
      {
        dataIndex: "totalPrice",
        title: "Сумма",
        render: text => `${text}₽`,
      },
    ],
    []
  );

  const getDataSource = useCallback(
    (data?: GetOrderList) => idx(data, x => x.orders),
    []
  );

  const expandedRowRender = useCallback<
    NonNullable<OrderTableProps["expandedRowRender"]>
  >(
    ({ products }) => (
      <Table<Product>
        style={{ margin: "0" }}
        bordered={true}
        columns={productTableColumns}
        dataSource={products || []}
        pagination={false}
        size="small"
      />
    ),
    [productTableColumns]
  );

  const {
    value: exportModalIsVisible,
    show: showExportModal,
    hide: hideExportModal,
  } = useToggleState();

  const {
    value: importModalIsVisible,
    show: showImportModal,
    hide: hideImportModal,
  } = useToggleState();

  return (
    <Authorize>
      <MainLayout
        title="Заказы"
        extra={
          <>
            <Button icon="import" onClick={showImportModal}>
              Импорт
            </Button>
            <Button icon="export" onClick={showExportModal}>
              Экспорт
            </Button>
          </>
        }
      >
        <GraphQLTable<Order, GetOrderList, GetOrderListVariables>
          columns={orderTableColumns}
          query={GET_ORDER_LIST}
          getDataSource={getDataSource}
          expandedRowRender={expandedRowRender}
        />
        <ExportOrdersModal
          visible={exportModalIsVisible}
          onClose={hideExportModal}
        />
        <ImportOrdersModal
          visible={importModalIsVisible}
          onCancel={hideImportModal}
        />
      </MainLayout>
    </Authorize>
  );
};

export default Orders;
